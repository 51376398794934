.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  margin: auto;
}

.modal-image {
  max-width: 100%;
  max-height: auto;
  margin-top: auto;
}

.nav-button {
  position: absolute;
  top: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 10%;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1001;
  margin: auto;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.close {
  position: absolute;
  top: -2%;
  padding: 1%;
  right: 5px;
  font-size: 4rem;
  color: white;
  cursor: pointer;
  z-index: 1001;
}

.close:hover {
  transition: all 500ms;
  color: #F5DEB3;
}

@media (max-width: 800px) {
  .close {
    position: absolute;
    top: -20px;
    padding: 1%;
    right: 12px;
    font-size: 4rem;
    color: white;
    cursor: pointer;
    z-index: 1001;
  }
}
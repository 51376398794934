@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.navbar {
  background-color: #4A4A4A; /*#2C3E50*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.navbar-logo {
  width: 40%;
  height: auto;
}

.navbar-logo img {
  width: 100%;
  height: auto;
}

.navbar-links ul {
  margin: 0;
  padding-right: .1rem;
  display: flex;
}

.navbar-links li {
  list-style: none;
  transition: background-color 0.5s ease, color 0.2s ease;
}

.navbar-links li a {
  text-decoration: none;
  font-size: large;
  color: white;
  padding: 1rem;
  display: block;
  transition: color 0.5s ease, background-color 0.2s ease;
}

.navbar-links li a:hover {
  background-color: #2f2f2f;
  color: #f5deb3;
}

.toggle-button {
  position: absolute;
  top: .50rem;
  right: 1rem;
  padding: 0;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 25px;
  background-color: transparent;
  border: none;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

.toggle-button.active .bar:nth-child(1) {
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  left: 0;
}

.toggle-button.active .bar:nth-child(2) {
  opacity: 0;
}

.toggle-button.active .bar:nth-child(3) {
  transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  left: 0;
}

.toggle-button:hover {
  background-color: transparent;
}


@media (max-width: 800px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-logo {
    width: 50%;
    height: auto;
  }

  .toggle-button {
    display: flex;
  }

  .navbar-links {
    display: none;
    width: 100%;
  }

  .navbar-links ul {
    width: 100%;
    flex-direction: column;
    background-color: #4A4A4A;
  }

  .navbar-links ul li {
    text-align: center;
  }

  .navbar-links ul li a {
    padding: .5rem 1rem;
  }

  .navbar-links.active {
    display: flex;
  }

  .toggle-button {
    display: flex;
  }

  .navbar-links.active {
    display: flex;
  }
}

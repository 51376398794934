footer {
    background-color: #EEECE8;
    color: #f5deb3;
    text-align: center;
    padding: 2%;
  }
  
  footer ul {
    list-style-type: none;
  }
  
  footer ul li {
    padding-bottom: 1%;
  }
  
  footer ul li img {
    margin-bottom: -15px;
    width: 2%;
    height: auto;
    margin-right: 10px;
    border: 1px solid #2f2f2f;
    border-radius: 100%;
    padding: 5px;
  }
  
  #footer-logo {
    width: 200px;
    height: auto;
  }
  
  @media (max-width: 800px) {
    #footer-logo {
      width: 150px;
    }
  
    footer ul li img {
      width: 10%;
    }
  
    footer ul li {
      padding-bottom: 5%;
    }
  }
  
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

/* General styles */
* {
  font-weight: 600;
  box-sizing: border-box; /* Added for consistent sizing */
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: 'Cormorant Garamond', serif; /* Ensure font-family is applied */
  color: #414141;
  background-color: #F4F5F3;
}

.cover-photo {
  position: relative;
  overflow: hidden;
}

.cover-photo img {
  width: 100%;
  height: 100%;
  transform: scale(1.1);
}

.cover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
  max-width: 80%;
  z-index: 2;
}

.cover-text h1 {
  font-size: 50px;
  color: #F5DEB3;
}

.cover-text h2 {
  font-size: 30px;
  color: #F5DEB3;
  width: 100%;
}

#cover-subtext {
  position: relative;
  transition: all 250ms;
  background: linear-gradient(to right, rgba(47, 47, 47, 0.1) 40%, transparent 55%);
  border-radius: 10px;
  margin: 10px 0;
  width: 100%;
}

#cover-subtext::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 47%;
  background-color: rgba(47, 47, 47, 0.9);
  z-index: -1;
  transition: all 300ms;
}

#cover-subtext:hover {
  color: #e8e8e8;
}

#cover-subtext:hover::before {
  width: 100%;
  background-color: rgba(47, 47, 47, 1);
}

/* General Container Styling */
.about {
  padding: 2%;
  text-align: center;
  background-color: #EEECE8;
}

/* Carousel */
.carousel {
  position: relative;
  width: 100%;
  height: auto;
  margin: auto;
  padding: 2% 3%;
}

.slick-slide {
  padding: 0 0.4%;
}

.slick-slide img {
  width: 100%;
  height: auto;
}

/* Apartment Section */
.apartment {
  border: 2px solid #2f2f2f;
  padding: 5%;
  text-align: center;
}

.apartment-button {
  border: 2px solid #2f2f2f;
  padding: 10px;
  margin: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.apartment-button:hover {
  background-color: #2f2f2f;
  color: #f5deb3;
}

/* Hide slick-prev and slick-next arrows */
.home-page.slick-prev, .home-page.slick-next {
  display: none !important;
}

/* Local Area */
.local-area {
  display: flex;
  padding: 3% 4%;
  background-color: #EEECE8;
}

.local-area-about {
  width: 55%; 
  padding: 0 5%;
  text-align: center;
}

.local-area-about ul {
  font-size: 20px;
  list-style-type: none;
}

.local-area-about ul li {
  position: relative;
  padding-left: 5%;
  padding-bottom: 2%;
}

.local-area-about ul li::before {
  content: 'X'; /* Custom bullet symbol */
  position: absolute;
  left: 0;
  color: black;
  font-size: 1.2em;
}

.local-area-image img {
  width: 45vw;
  height: auto;
  border: 2px solid #2f2f2f;
  padding: 4%;
  background-color: #F4F5F3;
}

/* Default state for elements that will be animated */
.animate-on-scroll {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.animate-on-scroll.in-view {
  opacity: 1;
}


.faq {
  padding: 3% 4%;
  background-color: #EEECE8;
  
}

.faq ul {
  font-size: 20px;
  list-style-type: none;
}

.faq ul li {
  position: relative;
  padding-left: 2%;
  padding-bottom: 10px;
}

.faq ul li::before {
  content: 'X'; /* Custom bullet symbol */
  position: absolute;
  left: 0;
  color: black;
  font-size: 1.2em;
}

/* Animation stuff*/
@media (prefers-reduced-motion: reduce) {
  .animate-on-scroll {
    opacity: 1;
    transform: none;
    transition: none;
  }

  .animate-on-scroll.in-view {
    opacity: 1;
    transform: none;
  }
}

/* Responsive Styles */
@media (max-width: 800px) {
  .body-container {
    font-weight: 400;
  }

  .cover-photo {
    padding-top: 30px;
    height: 40vh;
  }
  
  .cover-photo img {
    transform: scale(1.3);
  }

  .cover-text h1 {
    font-size: 45px;
  }

  .cover-text h2 {
    font-size: 20px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 15px;
  }

  h3 {
    font-size: 10px;
  }

  .about h1 {
    font-size: 40px;
  }

  .about h2 {
    font-size: 18px;
  }

  .about p {
    font-size: 18px;
  }

  .apartment {
    border: 1px solid #2f2f2f;
  }

  .apartment-button {
    border: 1px solid #2f2f2f;
    padding: 5px;
    margin: 5px;
  }

  .local-area-about h1 {
    font-size: 40px;
  }

  .local-area-about p {
    font-size: 18px;
  }

  .local-area-about ul {
    font-size: 15px;
  }

  .local-area {
    flex-direction: column;
  }

  .local-area-about {
    width: 95%; 
  }

  .local-area img {
    width: 93vw;
  }

  .local-area-image img {
    border: 1px solid #2f2f2f;
  }

  .faq h1{
    font-size: 40px;
  }
  
  .faq ul {
    font-size: 15px;
  }
  
  .faq ul li {
    padding-left: 7%;
  }
  
}

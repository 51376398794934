
form {
    max-width: 30vw;
    margin: 2% auto;
    padding: 20px;
    border-radius: 20px;
    border: 2px solid #2f2f2f;
}

input, textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    resize: none;
    border-radius: 20px;
    border: 2px solid #2f2f2f;
}


button {
    border: 2px solid #2f2f2f;
    padding: 10px;
    margin: 10px auto;
    display: block;
    transition: background-color 0.5s ease, color 0.5s ease;
}

  
button:hover {
    background-color: #2f2f2f;
    color: #f5deb3;
  }

.reservation-page .about{
    text-align: center;
    font-weight: 600;
    background-color: #F4F5F3;
}

.feedback-message {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 1rem;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    opacity: 1;
    transition: opacity 1s ease-out;
    z-index: 1000;
    width: 90%; 
    max-width: 500px; 
  }

  .feedback-message.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .feedback-message.error {
    background-color: #f8d7da;
    color: #721c24;
  }

  .feedback-message.fade-out {
    opacity: 0;
    transition: opacity 1s ease-out;
  }

@media (max-width: 800px) {
    form{
        max-width: 70vw;
    }

}
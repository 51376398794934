.apartment-pictures {
  padding: 2% 2%;
}

.apartment-pictures img {
  border: 2px solid #2f2f2f;
  padding: 5%;
  width: 100%;
  height: auto;
}

.slick-slide {
  padding: 20px;
}

.slick-prev, .slick-next {
  width: 40px;
  height: 40px;
  color: #333; /* Arrow color */
  border-radius: 50%; /* Circular background */
  line-height: 1;
  z-index: 1;
}

.slick-prev {
  left: -15px;
}

.slick-next {
  right: -15px;
}

/* Optional: Additional styling for better visibility */
.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 24px; /* Arrow size */
  color: #333; /* Arrow color */
}
.utilities {
  padding: 5%;
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  width: 100%; 
  background-color: #EEECE8;
}

.utilities-icon {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 200px; 
  margin: 10px;
}

.utilities-icon img {
  width: 40px;
  height: auto; 
}

.utilities-icon p {
  margin-left: 10px;
}


.price-list {
  padding: 2%;
  margin-bottom: 2%;
  border: 1px solid #2f2f2f;
  border-radius: 2%;
  text-align: center;
  width: 30vw;
  margin: 3% auto;
}

.price-list h1 {
  margin-bottom: 15px;
}

.price-list table {
  width: 100%;
  border-collapse: collapse;
  margin: auto;
}

.price-list th, .price-list td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  font-size: 20px;
}

.price-list th {
  background-color: #f4f4f4;
}

.price-list tr:nth-child(even) {
  background-color: #f9f9f9;
}

.price-list tr:hover {
  background-color: #f1f1f1;
}

/* Responsive Styles */
@media (max-width: 800px) {
  .apartment-pictures img {
    border: 1px solid #2f2f2f;
  }

  .utilities{
    padding-top: 10%;
  }
  
  .utilities p{
    font-size: 18px;
  }
  
  .utilities-icon {
    width: 30vw;
  }
  
  .price-list{
    width: 60vw;
    border: none;
  }

  .price-list th, .price-list td {
    font-size: 18px;
    text-align: center;
  }
}

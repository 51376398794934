@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* Global Styles */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: 'Cormorant Garamond', serif; /* Ensure font family is applied */
  font-weight: 600;
  color: #414141;
  background-color: #F4F5F3;
}

body::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: #333333;
}

a:active {
  color: #666666;
  outline: none; /* Removed redundant 'a:focus' */
}

.grecaptcha-badge {
  visibility: hidden; /* Hide reCAPTCHA badge */
}

/* Navbar */
.navbar {
  position: fixed; /* Ensure navbar stays at the top */
  top: 0;
  left: 0;
  right: 0;
  height: 60px; /* Navbar height */
  background-color: #fff;
  z-index: 1000; /* Ensure it's on top of other content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Main Content */
.main-content {
  padding-top: 60px; /* Space for navbar */
}

/* Headings */
h1 {
  font-size: 30px; /* Adjust heading size */
}

/* Paragraphs */
p {
  font-size: 20px; /* Adjust paragraph text size */
}

/* Buttons */
button {
  cursor: pointer; /* Pointer cursor for buttons */
}
